import { Row, Col, Container, Card, Form, Button, Table, Dropdown, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBusinesses, manageBusinessStatus, validateEmail } from "../../redux/slices/account.service";
import dateFormatter from "../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import Select from "react-select";
import RecordFilter from "../../components/RecordFilter";

const Businesses = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [tab, setTab] = useState('all')
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [pending_users, setPendingUsers] = useState([])
    const [pending_businesses, setPendingBusinesses] = useState([])
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({        
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
        request_type: '',
    })
    const [debounceTimer, setDebounceTimer] = useState(null);

    useEffect(() => {
        loadBusinesses()
    }, [payload.page, payload.request_type, payload.download])

    useEffect(() => {

        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }

        if(payload.filter.length >= 3) {
            const newTimer = setTimeout(() => {
              loadBusinesses();
            }, 500); 
        
            setDebounceTimer(newTimer);
        
            return () => clearTimeout(newTimer);
        }
    
    }, [payload.filter]);


    const loadBusinesses = () =>  {
        setLoading(true)
        dispatch(getBusinesses(payload))
        .then((response) => {
            if(response.payload.results) {
                if(tab === 'pending_users') {
                    setPendingUsers(response.payload.results)
                }
                else if(tab === 'pending_businesses') {
                    setPendingBusinesses(response.payload.results)
                }
                setResults(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleManagement = (id, option) => {
        Swal.fire({
            icon: 'warning',
            text: `Are you sure you want to ${option} this business?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if (!result.isConfirmed) return;
            manageBusiness(id, option)
        })
    }

    const manageBusiness = (id, option) => {
        console.log("id", id)
        console.log("option", option)
        dispatch(manageBusinessStatus({id, option}))
        .then(res => {
            if(res.payload?.success == true){
                loadBusinesses()
                Swal.fire({
                    icon: "success",
                    text: res.payload?.message
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: res.payload.message ??  "Something went wrong!"
                })
            }
        })
    }

    const toggleTab = (selected = 'all') => {
        setTab(selected)
        if(selected === 'all') {
            setPayload({...payload, request_type: ''})
        }
        else {
            setPayload({...payload, request_type: selected})
        }
    }

    const emailValidation = (email, option) => {
        Swal.fire({
            icon: 'warning',
            text: `Are you sure you want to ${option} this user?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, Cancel'
        })
        .then((result) => {
            if (!result.isConfirmed) return;
            
            dispatch(validateEmail({user_email: email, action: option}))
            .then((response) => {
                if(response.payload?.success === true) {
                    loadBusinesses()
                    Swal.fire({
                        icon: 'success',
                        text: response.payload?.message || 'Something went wrong'
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.payload?.message || 'Something went wrong'
                    })
                }
            })
        })
    }

    const handleSearch = () => {
        loadBusinesses()
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
    }
 
    return (
        <Layout title="Businesses">
            <Container className="mt-4 px-4 px-lg-4 px-sm-2 px-md-2 mb-4">
                <Row className="mb-3">
                    <Col md={12} className="mb-3 d-flex gap-2 wrap table-responsive-sm flex-wrap">
                        <Button 
                            type="button" 
                            variant="light" 
                            className="tab-buttons"
                            active={tab === 'all' ? true : false}
                            onClick={ () => toggleTab('all')}
                        >
                            Business lists
                        </Button>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="tab-buttons"
                            active={tab === 'pending_businesses' ? true : false}
                            onClick={ () => toggleTab('pending_businesses')}
                        >
                            Business Verifications
                        </Button>
                        <Button 
                            type="button" 
                            variant="light" 
                            className="tab-buttons"
                            active={tab === 'pending_users' ? true : false}
                            onClick={ () => toggleTab('pending_users')}
                        >
                            Email Verifications
                        </Button>
                    </Col>
                </Row>

                <div className="mt-4">
                    <Card className="mb-3 border-0 shadow-sm">
                        <Card.Body>
                            <Col className="d-flex justify-content-between">
                                <h5>Overview</h5>
                                <div className="d-flex justify-content-end">
                                    <p>
                                        <Button 
                                            variant="outline-primary" 
                                            type="button" 
                                            className="ms-3"
                                            onClick={ () => setPayload({...payload, download: 'True'}) }
                                        >
                                           <FontAwesomeIcon icon={'cloud-download'} />  Download
                                        </Button>
                                    </p>
                                </div>
                            </Col>
                            <Col className="mb-3 mt-3 analytic-row">
                                <div 
                                    className="shadow-sm rounded-3 card bg--primary border border-1" 
                                    onClick={ () => toggleTab('all')}
                                >
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon ">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-primary font-bold">{analytics?.no_of_businesses || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Total Businesses</p>
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    className="border border-1 shadow-sm rounded-3 card"
                                >
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon bg-light">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-success font-bold">{analytics?.no_of_active_businesses || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Total Active Businesses</p>
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    className="border border-1 shadow-sm rounded-3 card"
                                    onClick={ () => toggleTab('pending_users')}
                                >
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-danger font-bold">{analytics?.no_pending_email_verification || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Pending Emails</p>
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    className="border border-1 text-left shadow-sm rounded-3 card"
                                >
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-warning font-bold">{analytics?.no_of_starter_businesses || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Starter Business</p>
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    className="border border-1 shadow-sm rounded-3 card"
                                    onClick={ () => toggleTab('pending_businesses')}
                                >
                                    <div className="d-flex align-items-center gap-2 px-2 py-4">
                                        <div className="icon">
                                        </div>
                                        <div>
                                            <h3 className="m-0 text-secondary font-bold">{analytics?.no_pending_business_verification || '0'}</h3>
                                            <p className="text-muted text-sm m-0">Pending Verification</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Card.Body>
                    </Card>

                        <RecordFilter
                            handleInput={handleInput}
                            handleSearchSubmit={handleSearch}
                            params={[
                                { value: 'name', label: 'Business Name' },
                                { value: 'date', label: 'Date Registered' },
                                { value: 'request_type', label: 'Status' },
                            ]}
                        />

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive-sm">
                            <Table size="sm" className="table-color table-stripedd">
                                {
                                    tab === 'pending_users' ? 
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>Business Name</th>
                                                    <th>Email Address</th>
                                                    <th>Date Registered</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading ? (
                                                        <div className="in-page-loader">
                                                            <Spinner size="lg" variant="primary"/>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    pending_users?.length ? (
                                                        pending_users?.map((item, index) => (
                                                            <tr key={index+tab}>
                                                                <td>
                                                                    <div className="m-0 d-flex gap-2 align-items-center">
                                                                        <small className={item.is_verified === 'True' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                            {
                                                                                item.is_verified == 'True' ? 
                                                                                    <FontAwesomeIcon icon={'check-circle'} color="green" size="lg"/>
                                                                                : 
                                                                                    <FontAwesomeIcon icon={'times-circle'} color="red" size="lg"/>
                                                                            }
                                                                        </small>
                                                                        <strong>
                                                                            <p className="m-0">{`${item.first_name+' '+item.last_name}`} </p>  
                                                                        </strong>
                                                                    </div>
                                                                </td>
                                                                <td> {item.email}  </td>
                                                                <td>{dateFormatter(item.date_joined)}</td>
                                                                <td>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                            <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                            <Dropdown.Item 
                                                                                eventKey={1} 
                                                                                className="text-success d-flex align-items-center gap-1"
                                                                                onClick={ () => emailValidation(item.email, "validate") }
                                                                            >
                                                                                <FontAwesomeIcon icon={"check-double"} /> Validate
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Divider />
                                                                            <Dropdown.Item 
                                                                                eventKey={2} 
                                                                                className="text-danger d-flex align-items-center gap-1"
                                                                                onClick={ () => emailValidation(item.email, "invalidate") }
                                                                            >
                                                                                <FontAwesomeIcon icon={"times"} /> Invalidate
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={3} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>                                           
                                        </>
                                    :
                                    tab === 'pending_businesses' ? 
                                    <>
                                        <thead>
                                            <tr>
                                                <th>Business Name</th>
                                                <th>Email Address</th>
                                                <th>Date Registered</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading ? (
                                                    <div className="in-page-loader">
                                                        <Spinner size="lg" variant="primary"/>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                pending_businesses?.length ? (
                                                    pending_businesses?.map((item, index) => (
                                                        <tr key={index+tab}>
                                                            <td>
                                                                <div className="m-0 d-flex gap-2 align-items-center">
                                                                    <small className={item.is_verified === 'True' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                        {
                                                                            item.is_verified == 'True' ? 
                                                                                <FontAwesomeIcon icon={'check-circle'} color="green" size="lg"/>
                                                                            : 
                                                                                <FontAwesomeIcon icon={'times-circle'} color="red" size="lg"/>
                                                                        }
                                                                    </small>
                                                                    <strong>
                                                                        <p className="m-0">{`${item.store_name}`} </p>  
                                                                    </strong>
                                                                </div>
                                                            </td>
                                                            <td> {item.email}  </td>
                                                            <td>{dateFormatter(item.date_joined)}</td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => navigate(`/businesses/${item.merchant_id}`)}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-success d-flex align-items-center gap-1" 
                                                                            onClick={() => navigate(`/transactions/?merchant=${item.merchant_id}`)}
                                                                        >
                                                                        <FontAwesomeIcon icon={"list-check"} />  Transaction History
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        {
                                                                            item.status == "approved" ? 
                                                                            <>
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="text-dark d-flex align-items-center gap-1"
                                                                                    onClick={ () => handleManagement(item.merchant_id, "deactivate") }
                                                                                >
                                                                                    <FontAwesomeIcon icon={"cancel"} /> Deactivate
                                                                                </Dropdown.Item>
                                                                            </>
                                                                            : 
                                                                            <Dropdown.Item 
                                                                                eventKey={2} 
                                                                                className="text-success d-flex align-items-center gap-1"
                                                                                onClick={ () => handleManagement(item.merchant_id, "approve") }
                                                                            >
                                                                                <FontAwesomeIcon icon={"check-double"} /> Approve Business
                                                                            </Dropdown.Item>
                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                                : 
                                                (
                                                    <tr>
                                                        <td colSpan={3} className="text-center text-muted">No data found</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>                                           
                                    </>
                                    :
                                    <>
                                        <thead>
                                            <tr>
                                                <th>Business Name</th>
                                                <th>Email Address</th>
                                                <th>Date Registered</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading ? (
                                                    <div className="in-page-loader">
                                                        <Spinner size="lg" variant="primary"/>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                results?.length ? (
                                                    results?.map((item, index) => (
                                                        <tr key={index+tab}>
                                                            <td>
                                                                <div className="m-0 d-flex gap-2 align-items-center">
                                                                    <small className={item.is_verified === 'True' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                        {
                                                                            item.is_verified == 'True' ? 
                                                                                <FontAwesomeIcon icon={'check-circle'} color="green" size="lg"/>
                                                                            : 
                                                                                <FontAwesomeIcon icon={'times-circle'} color="red" size="lg"/>
                                                                        }
                                                                    </small>
                                                                    <strong>
                                                                        <p className="m-0">{`${item.store_name}`} </p>  
                                                                    </strong>
                                                                </div>
                                                            </td>
                                                            <td> {item.email}  </td>
                                                            <td>{dateFormatter(item.date_joined)}</td>
                                                            <td>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => navigate(`/businesses/${item.merchant_id}`)}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-success d-flex align-items-center gap-1" 
                                                                            onClick={() => navigate(`/transactions/?merchant=${item.merchant_id}`)}
                                                                        >
                                                                        <FontAwesomeIcon icon={"list-check"} />  Transaction History
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item 
                                                                            eventKey={2} 
                                                                            className="text-success d-flex align-items-center gap-1"
                                                                            onClick={ () => handleManagement(item.merchant_id, "approve") }
                                                                        >
                                                                            <FontAwesomeIcon icon={"check-double"} /> Approve Business
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                                : 
                                                (
                                                    <tr>
                                                        <td colSpan={3} className="text-center text-muted">No data found</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>                                           
                                    </>
                                }
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        results?.length ? (
                            <div className="table-responsive-sm mt-2">
                                <Paginator
                                    next={paginationData.next} 
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
            </Container>
        </Layout>
    );
}

export default Businesses;